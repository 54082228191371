.importMain{
    margin: 1rem 3rem;
}
.csvHeader{
    font-size: larger;
    margin-bottom: 1rem;
}
.csvLink a{    
    color:#0e72b5;
    font-size: medium;
}

.csvLink a:hover{    
    color:#0220a1;
    text-decoration: underline;
}
.importBlock{
    border: 1px solid rgb(122, 120, 120);
    padding: 1rem;
    background-color: #eff3f3;
    border-radius: 0.5rem;
}
.importErrorTable{
    margin: 2rem 3rem;
}
.importErrorText{
  color: #f44336;
  font-size: 1.5rem;  
}