.accordionSummary {
    border: 1px solid lightgray;
    border-radius: 0.3rem;
}


.gameCardHeading {
    height: 2rem;
    text-align: center;
    padding: 0.2rem;
    background-color: aliceblue;
}

.accordion {
    margin: 0 0 1rem 0;
}