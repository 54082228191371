.helpRow{
    margin:1rem;
    flex: 0 0 46%;
    width: 45%;
}

.helpHubBox {
    display: flex;
    flex-wrap: wrap;
    margin-right: 1rem;
    margin-left: 1rem;
}

.helpContainer {
    height: 22rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 900px){
    .helpHubBoxResponsive{
      display: block !important;
  }
  .helpRowResponsive {
    width: 96% !important;
  }
  }