.editStudentContainer {
  font-family: "Raleway" !important;
}
.inputStudent {
  display: block;
}
.inputWidth {
  width: -webkit-fill-available;
}
.gtxt {
  font-size: small;
  margin-left: 2%;
  margin-top: -1.5rem !important;
  margin-bottom: 1.5rem;
  color: #4e4e4ef0;
}
