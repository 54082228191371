.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: 2rem;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}
.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  margin: 0rem 2rem 2rem 0rem;
}

@media (min-width: 768px) {
  .row-cols-md-2 > * {
    flex: 0 0 46%;
    max-width: 50%;
  }
}

@media (min-width: 768px) {
  .d-md-block {
    display: block !important;
  }
}

@media (min-width: 1200px) {
  .row-cols-xl-4 > * {
    /* flex: 0 0 21%;
    max-width: 25%; */
    flex: 1;
  }
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.5rem;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1rem;
  /* //font-weight: 600; */
  font-size: 1.5rem;
  font-weight: 600;
}

element.style {
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}
.text-center,
.center {
  text-align: center;
}
.text-center {
  text-align: center !important;
}
.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}
.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  margin: 0;
}

@media screen and (min-width: 850px) and (min-width: 450px) {
  .btnResponsive {
    height: auto;
    width: max-content;
  }
}

.font1 {
  font-size: 1.1rem;
}

.font1 span {
  color: #0e72b5;
}
