header {
  align-items: center;
  background-color: #0071bc;
  color: #fff;
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
  position: fixed;
  width: 100%;
  z-index: 1000;
  height: 5rem;
  padding-top: env(safe-area-inset-top);
}

.headerLogo {
  font-size: 24px;
  height: auto;
  display: flex;
  align-items: center;
}

.logoImage {
  float: left;
  width: 99%;
  max-width: 20rem;
  min-width: 10rem;
}

.user-icon {
  cursor: pointer;
  font-size: 18px;
  z-index: 101;
  padding-right: 20px;
}

.popup {
  position: absolute;
  top: 4rem;
  right: 1rem;
  background-color: #ffffff;
  padding: 1rem;
  width: 17rem;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  font-size: 0.7rem;
  z-index: 102;
  color: black;
  background-color: #f5f5f5;
}

.popupDetails {
  display: flex;
  color: black !important;
}

.popup button {
  background-color: #22b6ba;
  color: #ffffff;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.popup button:hover {
  background-color: #1b999d;
}

.resetLink {
  display: flex;
  justify-content: center;
  font-size: larger;
}

.btnLogout {
  display: flex;
  justify-content: center;
}

.btnLightBlue {
  /* background-color: #22b6ba !important; */
  height: 3rem;
}

.WelcomeMsg {
  text-align: center;
  word-wrap: break-word;
  margin: 1rem 1rem 1rem 0rem !important;
  width: auto;
}

.changePasswordBtn {
  font-size: 0.875rem !important;
}

.itemGridCenter>.select-container {
  box-shadow: none;
  width: 15rem;
  padding: 0.7rem;
}

.headerLabel {
  color: white !important;
  font-size: large;
  padding-right: 0.5rem;
}

.MuiMenu-list {
  background: #e8e8e8;
  width: 13.5rem;
}

.dropdownOptions {
  min-height: 25px;
  align-items: center !important;
  justify-content: start !important;
  -moz-appearance: none;
  padding: 3px 1rem !important;
  white-space: normal;
  line-break: anywhere;
}

/* For mobile view, hide the dropdown in the main header */
@media (max-width: 849px) {
  .classDropdownBox {
    display: none !important;
  }

  /* Show the dropdown in the additional div */
  .dropdownMobile {
    display: flex;
    justify-content: center;
    padding: 1rem;
    background-color: #22b6ba;
  }
}

/* For desktop view, hide the dropdown in the additional div */
@media (min-width: 850px) {
  .dropdownMobile {
    display: none;

  }
}