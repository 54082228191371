.printMain{
    margin: 1rem 3rem;
}
.tableFont{
    font-size: 1.2rem;
    /* //font-weight: 600; */
}
.printTableHeading{
    font-size: 2rem;
    text-align: center;
    padding: 2rem 1rem;
    color: #838383;
    border-bottom: 1px solid #e0e0e0;
}


.printable-container {
    /* Add regular styles here for non-print view */
  
    @media print {
      /* Styles specific for print view */
      /* Hide elements that you don't want to include in the printout */
      body{
        /* visibility: hidden; */
        .pageHeading, .pageBreadCrumb{ visibility: hidden;}
        
      }
      
      .headerLogo{
        visibility: hidden;
      }
  
      .printable-container {
        /* Show the printable container and adjust styles as needed */
        
        visibility: visible;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }

  @media print {
    body {
      visibility: hidden;
    }
  
    .print-only  {
      visibility: visible;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
     
      /* width: fit-content; */
    }
  }