.adventuresMain{
    display: flex;
    
}

.adventuresLeft{
    width: 25%;
    height: 15rem;
    
  }

  .adventuresRight{
    width: 70%;
    border-right: 1px solid #e9ecef;
    /* border-bottom: 20px solid #e9ecef; */
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
  .episodeCardImage {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    max-height: 280px;
}
.adventureLogo {
    width: 100%;
    
    height: 100%;
    
    object-fit: cover;
    
  }

.adventuresHeading{
    background-color: #338dc9;
    padding: 1rem;
    color: #ffffff;
    border-top-right-radius: 0.5rem;
    font-size: larger;
}

.playBtn{
    position: relative;
    top: 2.5rem;
    display: flex;
    justify-content: center;
    cursor: pointer;
}
.btnLabel{
    color:#28a745;
    font-size: 1.2rem;
}
.brainIcon{
    vertical-align: middle;
    width: 2rem;
    height: 2rem;
    margin-right: 1rem;
}

.progressLine{
    padding: 0.5rem;
    
    /* border-radius: 0.2rem; */
}

.progressListDiv{
    position:relative;
    top: 3.75rem;   
}

.css-5xe99f-MuiLinearProgress-bar1 {
    background-color:#f8a84b !important;
}

@media screen and (max-width: 750px){
    .adventuresLeft {
        width: 30% !important;
    }
    .adventuresRight {
        width: 65% !important;
    }


}

@media screen and (max-width: 600px){
    .adventuresMain {
        display: block !important;
        margin-right: 2rem !important;

    }
    .adventuresLeft {
        width: 100% !important;
    }
    .adventuresRight {
        width: 100% !important;
        margin-bottom: 5rem !important;
    }

    .episodeCardImage {
        border-top-left-radius: 0rem !important;
         border-bottom-left-radius: 0rem !important;
    }
    .adventuresHeading {
        border-top-right-radius: 0rem !important;
    }
}