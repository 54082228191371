.video-container {
  position: relative;
  padding: 30px 10px;
  margin: 0 auto;
}
.gameContainer iframe {
  display: block;
  border-style: none;
  width: 640;
  height: 100vh !important;
  margin: 0 auto;
}
.video-container iframe {
  /* position: absolute; */
  top: 0;
  left: 0;
  width: 640px;
  height: 360px;
}

.primary-content {
  padding: 2rem 3rem;
  width: 100%;
}

.content-block {
  background-color: white;
  border-radius: 5px;
  box-shadow: 1px 1px 10px 0 black-10;
  color: grey;
  padding: 20px;
  width: 100%;
}

.avatar-column {
  align-content: center;
  flex: 1 1 100%;
  text-align: center;
}

.image-mask {
  margin: 10px auto 0;

  /* img {
    height: auto;
    width: 100%;
  } */
}

/* // Constrains a container on medium devices to a maximum width equal to a small device. */
.container-md-mwsm {
  width: 100%;

  @include media-breakpoint-up(md) {
    max-width: map-get($container-max-widths, sm);
  }
}

@include media-breakpoint-up(md) {
  .avatar-column {
    flex: 0 0 230px;
  }

  .content {
    margin-left: 320px;
    overflow-x: auto;
  }

  .content-block {
    padding: 40px 30px;
  }
}

@include media-breakpoint-up(lg) {
  .avatar-column {
    flex: 1 1 100%;
  }

  .content {
    max-width: calc(100% - 320px);
  }

  .content-block {
    padding: 70px 60px;
  }

  .primary-content {
    display: grid;
    grid-gap: 25px 20px;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    justify-items: center;

    &.wide-cols {
      grid-template-columns: repeat(auto-fill, minmax(600px, 1fr));
    }

    & > * {
      &:not(.content-card) {
        grid-column: 1 / -1;
        width: 100%;
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .avatar-column {
    flex: 0 0 230px;
  }
}

.lead {
  font-size: 1.25rem;
  /* //font-weight: 300; */
}
.autoOverflow {
  overflow: auto !important;
}
