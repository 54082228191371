.form {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: "2rem";
  margin: 0 2rem 2rem;
}

.checkboxContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.inputBox {
  display: flex;
  flex-direction: column;
}

.inputLabel {
  margin: 0 1rem;
  /* //font-weight: bold; */
  color: black;
}

.input::placeholder {
  color: rgb(145, 145, 145);
  font-size: 0.75rem;
}

.input::after {
  content: attr(placeholder);
  position: absolute;
  color: #161616;
  top: 0;
  left: 0;
}

.input:focus {
  border: none;
  box-shadow: 0px 1.5px 0px 0px #72e985;
  outline: none;
}

.input:focus + .input-helper {
  visibility: visible;
  transform: translateY(0rem);
}

.input:focus::placeholder {
  visibility: hidden;
}

.input {
  /* margin: 0.5rem 0.7rem 2rem; */
  margin: 0rem 0.7rem 2rem; 
  border: none;
  padding: 1rem;
  border-radius: 1rem;
  background: #e8e8e8;
  box-shadow: 4px 5px 6px #c5c5c5, -20px -20px 60px #ffffff;
  transition: 0.3s;
  opacity: 0.8;
}

.input:focus {
  outline-color: #e8e8e8;
  background: #e8e8e8;
  box-shadow: inset 20px 20px 60px #c5c5c5, inset -20px -20px 60px #ffffff;
  transition: 0.3s;
}

.formContents {
  display: flex;
  padding: 0 0 2rem 0;
}
.teacherSection {
  width: auto;
}
.teacherLogoHeading {
  font-size: x-large;
}
.teacherLogo {
  width: 100%;
}
.classesList {
   /* height: calc(100vh - 200px);  */
   height: 25rem; 
  overflow: auto ;
 }
 .btnFont{
  font-size: 0.8rem !important;
 }
.error {
  color: red;
  margin: 0rem 1rem 1.5rem;
}
.errorInput {
  margin: 0.5rem 0.7rem 0rem;
}

.loaderContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background: rgba(0, 0, 0, 0.834);
  z-index: 111;
}

.spinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
   margin-right:20%;
  margin-bottom:5%; 
  border-color: #3d5af1 transparent #3d5af1 transparent;
  border-radius: 50%;
  animation: spin-anim 1.2s linear infinite;
}

@keyframes spin-anim {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}
.ResponsiveBtnHeader{display:none;}
.webBtnHeader{display: flex;}
@media only screen and (min-width: 620px) {
  .spinner {    
    margin-right:20%;
    margin-bottom:0%; 
  }
  .ResponsiveBtnHeader{display:block;}
.webBtnHeader{display: none;}
.itemCenter{
  display: flex;
justify-content: center;}
}

@media screen and (max-width: 600px){
  .textInputResponsive{
    display: block !important;
    width: 100%;
}
.ml2Responsive{
  margin-left: 0!important;
}
.MuiGrid-spacing-xs-2 > .MuiGrid-item {  
  width: 100%;
}
}

@media screen and (min-width: 450px){
  .btnResponsive{
    height: auto;
    width: max-content;
}
.btnFitResponsive {
  height: auto;
  width: fit-content;
}
}