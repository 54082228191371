.navbar-container {
  background-color: #2a3f54;
  min-width: min-content;
  z-index: 10;
  background-image: url(../../assests/images/kneo-bg2.png);
  overflow: auto;
  background-size: cover;
  max-width: min-content;
}
@media screen and (orientation: portrait) {
  .navbar-container {
    padding-bottom: 5rem;
  }
}
.navbar-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: max-content;
}
.navbar-item {
  display: flex;
  align-items: center;
  height: 3rem;
  width: 100%;
  padding: 0 0 0 1rem;
  text-decoration: none;
}
.navbar-list a {
  text-decoration: none;
  text-transform: capitalize;
  /* //font-weight: 500; */
  font-size: 13px;
  letter-spacing: 1px;
}
.navbar-list li {
  color: #fff;
  font-size: 1.1rem;
  /* //font-weight: bold; */
}
.navbar-list a.active li {
  background-color: rgba(145, 158, 171, 0.08);
  color: #fff;
}

.navbar-item:hover {
  background-color: rgba(145, 158, 171, 0.08);
  color: #fff;
}
.navbar-item .MuiSvgIcon-root {
  position: absolute;
  right: 10px;
}
.navSubItem {
  padding-left: 3.5rem;
}
.navbar-icon {
  display: inline-block;
}

.navbar-icon svg {
  padding: 1rem;
  color: #fff;
}

.navbar-row {
  display: flex;
  padding: 0.25rem;
}

.navbar-container::-webkit-scrollbar {
  width: 3px;
  height: 5px;
}

.navbar-support-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.navbar-support-text {
  text-align: center;
  color: white;
  font-size: 0.875rem;
  white-space: pre-wrap;
}

.support-email {
  font-weight: bold;
  color: #fff;
}

.visible {
  padding: 1rem;
}

.hidden {
  display: none;
}
