.enrollStudentContainer{
    font-family: 'Raleway'!important;
        margin: 1rem  2rem;
}
.headerText{
    font-size: x-large;
}
.subHeaderText{
    font-size: 1rem;
    padding-left: 1rem;
}
.subText{
    font-size: 1rem;
    padding-left: 1rem;
}
.stdEnrollForm{
    padding-left: 1.2rem!important;    
}
.studentMain{
    display: flex;
    justify-content: space-between;
}
.studentMainLeft{
    padding-left: 1.2rem;   
}
.inputAlign{
    margin-top: 1% !important;
}
.newStudHeading{
    font-size: x-large;
}
.gmailTxt {
    font-size: small;
    padding-left: 1.5rem;
    margin-top: -1.5rem !important;
    color: #4e4e4ef0;
}

.lessonTxt{
    font-size: 1rem;    
    padding-left: 1rem;
     margin-bottom: 0.5rem;  
    color:#4e4e4ef0;
}
.lessonGroup{
    display: flex;
    border: 1px solid #ced4da;
    border-radius: 0.3rem;
    
}
.leftBox{
    width:40%;
    border-right: 1px solid #ced4da;
    overflow-y: scroll;
    /* height: 14.5rem; */
    margin: 1rem;
}
.rightBox{
    width: 60%;
    padding: 1rem 1.5rem;
    overflow-y: scroll;
}
.lessonLabel{
    margin-bottom: 1rem;
    /* //font-weight: bold; */
    color: #5f5f5f;

}
.boxBgStyle{
    background-color: #0071bc;
    color: #ffffff;
    text-align: center;
    position: sticky;
    top: 0px;
    padding: 0.5rem;
}
.leftBox {
    padding: 0rem 2rem;
}
.leftBox ul{
    margin:0;
    padding:0;
}
.drpLabel{
    margin-left: 1rem;
}
.studentMainRight{
    margin-top: 1.2rem;
}
.drpOptions{
    height: 15rem;
}
.leftBox li{
    padding: 0.5rem 0.5em;
    list-style-type: none;
    cursor: pointer;
}
.hoveredLesson {
    background-color: #f0efef;
  }
.activeLesson {
    background: #0071bc;
    color: #ffffff;
    border-radius: 0.5rem;
    box-shadow: 4px 5px 6px #c5c5c5, -20px -10px 10px #ffffff;
}
.sideMargins{
    margin: 0rem 1.2rem;
    height: 20rem;
}

.lessonTxt a{
    color:#007bff;
}

.lessonTxt a:hover{
    color:#007abc;
    text-decoration: underline;
}
@media screen and (max-width: 600px) {
    .lessonGroup {
        display: block !important;}
.leftBox{
    overflow-y: scroll;
    height: 14.5rem;
    width: 100% !important;
}
 .rightBox{width: 100% !important;}
    .sideMargins {        
        height: 50rem !important;
    }
    .studentMain {
        display: block !important;}
        .studentMainRight {           
            margin-left: 3rem;
        }
    }