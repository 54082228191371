.studentDashboard {
  font-family: "Raleway" !important;
  margin: 2rem;
}
.cardGoup {
  display: flex;
  flex-flow: row wrap;
}
.cardGoup > .card:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.cardGoup > .card {
  flex: 1 0 0%;
  margin-bottom: 0;
}
.studentCard {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  min-width: 0;
  width: 50%;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border-radius: 0.25rem;
}
.cardBorder {
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.cardBorderLeft {
  border-left: 1px solid rgba(0, 0, 0, 0.125);
}
.cardBorderRight {
  border-right: 1px solid rgba(0, 0, 0, 0.125);
}
.classWidth {
  width: 70%;
}

.cardHeader {
  padding: 0.5rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.cardHeader:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.cardHeader {
  border-top-right-radius: 0;
}

.cardBody {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}
.cardTitle {
  text-align: center;
  font-size: 18px;
}
.imageMask {
  height: 200px;
  overflow: hidden;
  width: 200px;
  margin: 10px auto 0;
}
.stdAlign {
  margin-left: 32% !important;
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  /* margin-top: 1rem; */
  color: #212529;
  /* line-height: 3rem; */
}
table {
  border-collapse: collapse;
}
.tableList {
  color: #0071bc;
}
.btnGroup,
.btnGroupVertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-info.active:not(:disabled):not(.disabled),
.show > .btnInfo {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}
.btnGroup > .btn:not(:last-child):not(.dropdown-toggle),
.btnGroup > .btnGroup:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btnGroup > .btn:hover,
.btnGroupVertical > .btn:hover {
  z-index: 1;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.btnGroup > .btn,
.btnGroupVertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn:hover {
  color: #212529;
  text-decoration: none;
}
.textWeight {
  font-size: large;
  display: flex;
}

.switchContainer {
  margin-top: 1rem;
  margin-left: 0.5rem;
}

.switch {
  font-size: 17px;
  position: relative;
  display: inline-block;
  width: 5.2em;
  height: 2rem;
  overflow: hidden;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #eee;
  transition: 0.4s;
  border-radius: 30px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 1.4em;
  width: 1.4em;
  border-radius: 20px;
  border: 1px solid #333;
  left: 0.4em;
  bottom: 0.2em;
  background-color: white;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #338dc9;
}

input:focus + .slider {
  box-shadow: 0 0 1px #338dc9;
}

input:checked + .slider:before {
  transform: translateX(3em);
}

.switch .text {
  position: absolute;
  top: 50%;
  pointer-events: none;
  text-transform: uppercase;
  transform: translateY(-50%);
  transition: 0.4s;
}

.switch .text.on {
  left: 0.8rem;
  transform: translateX(-3rem) translateY(-50%);
  color: #fff;
}

.switch .text.off {
  color: #000;
  right: 0.8rem;
}

input:checked ~ .text.off {
  transform: translateX(3rem) translateY(-50%);
}

input:checked ~ .text.on {
  transform: translateX(0) translateY(-50%);
}
.standardBasedLessons,
.ProgressChart {
  margin: 2rem;
}
.chartHeading {
  font-size: 1.5rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
}
.chartOutline {
  width: 60%;
}
.chartRight {
  width: 40%;
}
.chartTitle {
  font-size: 1.2rem;
  margin-top: 2rem;
}
.repoBox {
  display: flex;
  outline: 1px solid #dfdfdf;
}
.chartList {
  margin-top: 1rem;
}
.chartList ul {
  margin: 0;
  padding: 0;
}
.chartList li {
  list-style-type: none;
  line-height: 3rem;
}

.iconColor {
  color: #22b6ba;
}

.iconColorOrange {
  color: #22b6ba;
}

.RepoIcon {
  vertical-align: middle;
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
}
.litColor {
  color: #0071bc;
}

.matColor {
  color: #aacb5f;
}
.iconColorWhite {
  color: #ffffff;
}

.scienceColor {
  color: #4ca8e691;
}
.stemColor {
  color: #f8a84b;
}
.difficultColor {
  color: #f2accc;
}

.interColor {
  color: #69469e;
}

.beginColor {
  color: #f8a84b;
}
.chartList li a {
  color: #0071bc;
}
.chartList li a:hover {
  text-decoration: underline;
}
.barApp {
  width: 95%;
}

.ProgressMain,
.GamesMain {
  margin: 2rem;
}
.colLeft img {
  /* width: 100%;
  height: auto;
  margin-right: 1rem; */
}
.reportLogo {
  min-width: 85%;
  max-width: 5rem;
}
.colMain {
  display: flex;
  text-align: center;
}
.colLeft {
  width: 35%;
}

.colRight {
  margin-left: 1rem;
  text-align: center;
  /* margin-top: 2.5rem; */
}

.progressBox {
  outline: 1px solid #dfdfdf;
}
.ReportFont {
  font-size: 1.2rem;
}
.barPadding {
  /* padding: 2rem; */
  height: 35rem;
  padding-top: 2rem;
}
.barPaddingProgress {
  padding: 1rem;
  height: 30rem;
  width: 100%;
}

.studentOneCard {
  position: relative;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border-radius: 0.25rem;
}
.gameCaption {
  margin-top: 0.5rem;
  margin-left: 15rem;
  color: #6b6b6b;
}
@media screen and (max-width: 600px) {
  .studentCardResponsive {
    display: block;
    width: 100%;
  }
}
