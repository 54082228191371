.ViewProgressMain{
    margin:2rem;
}
.viewHeading{
    font-size: 1.8rem;
}

.overviewMain{
    display: flex;
    border: 1px solid rgba(0, 0, 0, 0.125);
    padding: 2rem 1rem;
}
.overviewLeft{
    width: 50%;
}
.overviewRight{
    /* width: 45%;  */
    margin-left: 2rem;
}
.overviewTitle{
    font-size: 1.3rem; 
    margin-bottom: 1rem;
}
.overviewRight ul{
    margin: 0;
    padding:0;
}
.overviewRight li{
    display: block;
    line-height: 2rem;
}
.overviewrightTitle{
    font-size: 1.2rem;
}

.prePostMain{
    
    border: 1px solid rgba(0, 0, 0, 0.125);
    padding: 2rem 1rem;
}
.preMain{
 display: flex; 
}
.preLeft{
    width: 50%;
}
.preRight{
    width: 50%;
    /* margin-left: 3rem;
    margin-top: 3rem; */
}
.graphTitle{
    text-align: center;
   

}

.bartext{
    text-align: center;
    color:#7a7a7a;
}
.preTitle{
    font-size: 1.5rem;
}
.reviewMainBox{
    border: 1px solid rgba(0, 0, 0, 0.125);
    padding: 2rem 1rem;
}
.reviewMain{
    display: flex;
}
.reviewLeft{
    width: 50%;
}
.reviewRight{
    width: 50%;

}
.reviewGameLeft{
    width: 80%;
}
.reviewGameRight{
    width: 20%;

}
.reviewTable{
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
}
.borderBtm{
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
   
}
.cmpGameBox{
    border: 1px solid rgba(0, 0, 0, 0.125);
    padding: 2rem 1rem;
}
.cmpGameMain{
    display: flex;
}
.cmpGameLeft{
    width: 50%;
}
.cmpGameRight{
    width: 50%;
}
@media screen and (max-width: 700px){

    .overviewMain {
        display: block !important
    }
    .overviewLeft {
        width: 100% !important;
    }
    .overviewRight {
        width: 100% !important;
        margin-top: 2rem !important;
        margin-left: 0rem !important;
    }
    .preMain {
        display: block !important;
    }
    .preLeft {
        width: 100% !important;
        margin-top: 2rem !important;

    }
    .preRight {
        width: 100% !important;
    }
    .barPadding {
        margin-top: 2rem !important;
         padding: 0rem !important; 
         height: 15rem !important; 
    }
    .reviewMain {
        display: block;
    }
    .reviewLeft {
        width: 100% !important;
    }
    .reviewRight {
        width: 100% !important;
        margin-top: 2rem !important;
        margin-left: 0rem !important;
    }
    .reviewGameLeft {
        width: 100%;
    }
    .reviewGameRight {
        width: 100%;
        margin-left: 2rem !important;
    }
    .cmpGameMain {
        display: block;
    }
    .cmpGameLeft {
        width: 100%;
        margin-top: 2rem !important;
    }
    .cmpGameRight {
        width: 100%;
    }
}