.lessonHeading{
    font-size: 1.5rem;
    margin-left: 3rem;
    
}
.imgHead{
display: flex;
margin-top:2rem;
margin-left: 4rem;
/* justify-content: space-around; */
}
.imgLink{
    margin-top: 5rem;
    margin-left: 3rem;
}
.imgLink a{
color:#0e72b5;

}
.imgLink a:hover{
    color:#045c97;
    text-decoration: underline;
}

.resourceBlock{
    border: 1px solid #dfdfdf;
    border-radius: 1rem;
    margin: 1rem 3rem;
    padding: 1rem;
    box-shadow: 0 4px rgba(0, 0, 0, 0.1);
   
}

.lessonHeading{
    /* border-bottom: 1px solid #dfdfdf;
    background-color: red; */
}


.cardHeader {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    font-size: 1.25rem;
    text-align: center
}
.cardBody {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem;
    text-align: center
}

.cardFooter {
    padding: 0.75rem 1.25rem;
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.cardFooter a{
    color: #0e72b5;
}
.cardFooter a:hover{
    text-decoration: underline;
    color:#0071bc;
}
.cardRow {
    display: flex;
    /* flex-wrap: wrap; */
    margin-left: 2rem;
    margin-right: 1rem;
    
    
}
.cardRowh{
     margin:1rem;
     /* flex: 1; */
}
.w-45{
    width:45%;
}

.preDiv{
    border: 1px solid #dcdcdc;
    margin-left: 2rem;
    margin-right: 2rem;
    margin-bottom: 2rem;
    /* border-radius: 0.5rem;
    box-shadow: 4px 5px 6px #c5c5c5, -20px -20px 60px #ffffff;
    transition: 0.3s; */
    
}
.headinFont{
    font-size: larger;
    font-weight: bold;
    color:#fff;
    background-color: rgb(92, 90, 90);
    /* text-decoration: underline; */
}

.bgColor{
    background-color: #4C336E;
}

.tablecellFont{
    font-size: 15px;
}

.reportTable .MuiTableCell-root{
     border: 1px solid #dcdcdc;
}
.imgHeight{
    height: 12.5rem;
}
.preFont{
    font-size: 1.3rem;
}
.skillFont{
    font-size: 1rem;  
}
.cardFooterHeight{
    height: 4.5rem;
}
@media screen and (max-width: 750px){
    .cardRow {
        display: block !important;
    }
    .w-45 {
        width: 93% !important
    }

}