.tHeading {
  font-size: large;
}

.legendHeading {
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
}

.legends {
  text-align: justify;
  width: max-content;
  padding: 5px 10px;
}

.legendName {
  font-size: 13px;
}

.legendBody {
  border: 3px solid black;
}

.legendsRow {
  display: flex;
  align-items: center;
}

.legendIcon {
  height: 1.5rem;
  width: 1.5rem;
  padding: 0.3rem;
  margin-left: 0.3rem;
}

.legendYellow {
  background-color: #ece817;
  border: 3px solid black;
  width: 2rem;
  height: 2rem;
  margin: 6px;
}

.legendPurple {
  background-color: #eba7e5db;
  border: 3px solid black;
  width: 2rem;
  height: 2rem;
  margin: 6px;
}
.legendDarkPurple {
  border: 1px solid black;
  color: white;
  padding: 3px;
  background-color: rgb(122 68 161);
}

.legendGreen {
  background-color: rgba(87, 178, 87, 0.71);
  border: 3px solid black;
  width: 2rem;
  height: 2rem;
  margin: 6px;
}

.legendOrange {
  background-color: rgb(240, 191, 135);
  border: 3px solid black;
  width: 2rem;
  height: 2rem;
  margin: 6px;
}

.legendWhite {
  font-size: 1.2rem;
  background-color: white;
  border: 1px solid;
  border-width: 3px 3px 0px 3px;
}

.centerAlign {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

@media screen and (min-width: 1951px) {
  .displayF {
    display: flex;
  }
  .assessmentContainer {
    width: 50%;
  }
}
@media screen and (max-width: 1950px) {
  .displayF {
    display: block;
  }
  .assessmentContainer {
    width: 100%;
  }
}

.colorWhite {
  color: white;
}

.cardAlternate {
  box-shadow: none !important;
  height: fit-content !important;
}

.lrn p,
.lrn ul {
  width: 90%;
}

.slides-control:after {
  position: static !important;
}

.legendColumn {
  display: flex;
}

@media screen and (max-width: 600px) {
  .legendColumn {
    display: block;
  }
}

@media screen and (max-width: 900px) {
  .navActive {
    display: block;
  }
}

.flexContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}

.unitSelectionContainer {
  flex: 1 1 auto;
  min-width: 250px;
}

.scoringKeyContainer {
  margin-left: auto;
  min-width: 250px;
  padding-left: 2rem;
}

.tableHeader {
  position: sticky;
  top: 0;
  z-index: 3;
  outline: "1px solid #e0e0e0";
}

.levelCell {
  background-color: rgb(245, 245, 245);
}
