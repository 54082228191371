.btnTable {
  margin-top: 0.4rem !important;
  margin-right: 0.4rem;
}
.pageHeading {
  font-size: 2rem;
  width: fit-content;
  color: #333;
  border-radius: 4px;
  box-shadow: 0 4px rgba(0, 0, 0, 0.1);
  margin: 1rem 2rem 0;
  padding: 0.5rem;
  overflow-wrap: break-word;
  word-break: break-word;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* .Card-openNav { */
/* width: 78%!important; */
/* } */
.tableIcons {
  font-size: 1.4rem;
  margin-right: 1rem;
  border: 0;
  background-color: #fff;
}
.TeacherTable {
  margin: 20px;
}
.TableTitle {
  font-size: 1.5rem;
  /* font-weight: 600; */
}

.classheight {
  height: 2rem;
}

.detailSection {
  width: 100%;
  margin-left: 1rem;
}
/*responsiveness*/

@media screen and (min-width: 450px) {
  .btnResponsive {
    height: auto;
    width: max-content;
  }
}
@media screen and (max-width: 600px) {
  .textInputResponsive {
    display: block !important;
    width: 100%;
  }
  .formContents {
    display: block !important;
  }
  .teacherSection {
    text-align: center;
  }
  .teacherLogo {
    min-width: 50% !important;
  }
  .MuiGrid-spacing-xs-2 > .MuiGrid-item {
    margin-top: 2rem !important;
  }
  .teacherLogoHeading {
    font-size: large !important;
  }
  .pt-0Responsive {
    padding-top: 0 !important;
  }
  .assessBtnResponsive {
    display: block;
    width: fit-content;
    line-height: 1rem;
  }
  .btnWidthResponsive {
    width: -webkit-fill-available;
  }
  .ml-2Responsive {
    margin-left: 2rem !important;
  }
  .ml-3Responsive {
    margin-left: 3rem !important;
  }
  .repoTableWidthResponsive {
    width: 93% !important;
  }
}
@media screen and (max-width: 500px) {
  .dialogContainer > .MuiDialog-container > .MuiDialog-paper {
    border-radius: 12px;
    min-width: 20rem;
  }
  .btnAlignResponsive {
    display: block;
  }
}

.logoContainer {
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
}

.inputRow {
  display: flex;
  flex-wrap: wrap;
}

.inputContainer {
  flex: 1 1 15rem;
}

.min-h-3 {
  min-height: 3rem;
}
