.accountMain{
    margin-left: 3rem;
    margin-bottom: 2rem;
}
.table-borderless{
    line-height: 3rem;
}
.licensceMain{
    margin-top: 1rem;
    margin-left: 2rem;
    margin-right: 2rem;
}
.schoolDetails{
    margin-left: 3rem;
    margin-top: 1rem;
    
}
.schoolDetailsHeading{
    font-size: 1.5rem;
    margin-bottom: 1rem;
}
.orderdiv{
    margin: 2rem 3rem;
}
.accountTitle {
    font-size: 2rem;
}

.leftTitle {
    width: 40%;
    display: inline-table;
}
.rightData {
    width: 60%;
}

.ulList > ul {
    margin: 0px;
    padding-left: 1rem;
}